<template>
<div>
    <!-- <v-row v-show="$vuetify.breakpoint.mobile"></v-row>
        <v-row style="margin-top: 0%" v-show="!$vuetify.breakpoint.mobile"></v-row> -->
    <waiting-modal :title="title" :status="status" :active="active_waiting" @statusdailog="update_status_wait"></waiting-modal>
    <!-- {{date}} -->
    <v-dialog persistent v-model="reset_password_dialog" width="500">
        <v-card>
            <v-card-title>
                <span style="color:#a84192; font-size:18px;">โปรดกรอก Email เพื่อ reset password</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-form ref="form" v-model="valid" lazy-validation style="width: 100%">
                        <v-text-field v-model="username" :rules="nameRules" label="email" dense outlined required style="padding-left: 0px !important;"></v-text-field>
                    </v-form>
                    <br>
                    <v-row class="d-flex justify-space-around">
                        <v-btn color="primary" outlined style="color:#a84192" @click="reset_password_dialog = false">
                            ยกเลิก
                        </v-btn>
                        <v-btn color="#a84192" width="40%" class="text-white" @click="admin_reset">
                            ตกลง
                        </v-btn>
                    </v-row>
                    <br>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-container>
        <v-row class="align-center d-flex h-0 pb-20">
            <v-col xl="3" lg="4" sm="8" class="mx-auto">
                <br />
                <v-row class="d-flex">
                    <v-col col="12" style="text-align: center">
                        <span class="text-purple" style="font-weight: 600; font-size: 20px">Token management system</span>
                    </v-col>
                </v-row>
                <br />
                <!-- @click:append=on  -->

                <v-card>
                    <v-card-text>
                        <v-form ref="form" v-model="valid" lazy-validation style="width: 100%">
                            <v-text-field v-model="username" :rules="nameRules" label="email" dense outlined required style="padding-left: 0px !important;"></v-text-field>
                            <v-text-field v-model="password" :rules="nameRules" label="password" dense outlined required style="padding-left: 0px !important;"></v-text-field>
                        </v-form>
                        <v-row class="d-flex justify-end">
                            <!-- <v-btn text @click="reset_password_dialog=true">Reset password</v-btn> -->
                        </v-row>

                    </v-card-text>
                    <v-card-actions class="d-flex align-center justify-center">

                        <v-btn style="width: 250px" @click="validate" color="bg-primary text-white" class="border-radius-xl">login</v-btn>
                    </v-card-actions>
                </v-card>

            </v-col>
        </v-row>

    </v-container>
</div>
</template>

<script>
import {
    APIURL
} from '@/constants'
import Vue from 'vue'
import WaitingModal from "@/components/WaitingModal.vue";
export default {
    name: "calendar-patient",
    components: {

        WaitingModal
    },

    data: () => ({
        username: '',
        password: '',
        valid: true,
        reset_password_dialog: false,
        modal: false,
        showPicker: false,
        active_waiting: false,
        title: '',
        status: '',
        provider_list_id: '',
        provider_list: [],
        activePicker: null,
        date: null,
        booking_time: '',
        detail: '',
        menu: false,
        selected_row: "",
        dialog: false,
        arrayEvents: null,
        date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),

        name: "",
        nameRules: [
            (v) => !!v || "โปรดกรอกข้อมูล"
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    }),

    mounted() {},
    watch: {
        date(val) {
            //console.log('picked date')
            this.date = val
            // this.modal = false
        }
    },
    methods: {
        toggledate() {
            //console.log('toggledate')

        },
        update_status_wait(val) {
            this.active_waiting = val
        },
        async submitVal() {

            this.dialog_new = false
            this.active_waiting = true
            this.title = 'กำลังเข้าสู่ระบบ',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/admin_login', {
                "username": this.username,
                "password": this.password,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data) {
                    this.title = 'ล็อกอินสำเร็จ';
                    this.status = 'done'
                    this.$store.commit('setAdminLogin', {
                        'is_admin_login': true
                    });
                    setTimeout(() => {
                        this.active_waiting = false
                    }, 2000)
                    // this.get_alert_record()
                    setTimeout(function(){localStorage.removeItem('store');}, 7200 * 1000);
                    this.$refs.form.reset()
                    this.$router.push('/token_management')
                } else if (!response.data) {
                    this.title = 'user / password ไม่ถูกต้อง';
                    this.status = 'fail'
                } else {
                    this.active_waiting = false
                    this.title = 'ระบบขัดข้อง',
                        this.status = 'fail'
                }

            })

        },
        async admin_reset() {

            this.dialog_new = false
            this.active_waiting = true
            this.title = 'กำลังตรวจสอบ',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/admin_reset', {
                "username": this.username,
                "password": this.password,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data) {
                    this.title = 'ระบบได้ส่งลิ้งค์ในการ reset รหัส ไปยังอีเมลของท่านแล้ว โปรดเช็คใน Inbox หรือ Spam mail';
                    this.status = 'info'
                    // this.$store.commit('setAdminLogin', {
                    //     'is_admin_login': true
                    // });
                    // setTimeout(() => {
                    //     this.active_waiting = false
                    // }, 2000)
                    // // this.get_alert_record()
                    // this.$refs.form.reset()
                    // this.$router.push('/token_management')
                } else if (!response.data) {
                    this.title = 'user / password ไม่ถูกต้อง';
                    this.status = 'fail'
                } else {
                    this.active_waiting = false
                    this.title = 'ระบบขัดข้อง',
                        this.status = 'fail'
                }

            })

        },

        save(date) {
            this.$refs.menu.save(date);
        },
        validate() {
            const res = this.$refs.form.validate();
            if (res) {
                // this.$router.push('/book_doctor_list')
                this.submitVal()
            }
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>

<style>
.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}

.action-nav {
    background: #ffffff !important;
    box-shadow: 0px -2px 4px rgba(207, 202, 202, 0.25) !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
    height: 77 px !important;
}

.v-menu__content .v-list-item__title {
    font-size: 0.875rem;
    color: #ffffff;
    color: gray;
}

.custom-field {
    border-radius: 6px;
}

.v-picker__title {
    color: #ffffff;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    padding: 16px;
    background-color: #a84192;
}

.v-main__wrap {
    padding-bottom: 90px;
}

.position-relative {
    position: relative;
}

.v-date-picker-table .v-btn.v-btn--active {
    color: #ffffff;
    background-color: #a84192;
}

.theme--light.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) button:not(:hover):not(:focus) {
    color: #a84192 !important;
}

.theme--light.v-btn.v-btn--icon {
    color: #a84192 !important;
}

.v-dialog>.v-card>.v-card__text {
    padding: 0 24px 0px;
}
</style>
